import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import axios from '../../axiosConfig';
import Message from '../Message';
import Sidebar from '../Sidebar';
import config from '../../config';
import './UserDetails.css';

Modal.setAppElement('#root');

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    fullname: '',
    username: '',
    email: '',
    description: '',
    role: 'user',
    status: 1,
  });
  const [existingImages, setExistingImages] = useState([]);
  const [msg, setMsg] = useState({ type: "", text: "" });
  const [avatarFile, setAvatarFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  // MODAL
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`/users/profile/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setUser(response.data);
        setFormData({
          fullname: response.data.fullname || '',
          username: response.data.username || '',
          email: response.data.email || '',
          description: response.data.description || '',
          role: response.data.role || 'user',
          status: response.data.status,
        });
        const existImageArray = [];
        if (response?.data?.avatar) {
          existImageArray.push({ url: response.data?.avatar, name: 'Avatar' })
        }
        if (response?.data?.banner) {
          existImageArray.push({ url: response.data?.banner, name: 'Bannière' })
        }
        setExistingImages(existImageArray);
      } catch (error) {
        setMsg({ type: "error", text: "Impossible de récupérer les informations de l'utilisateur." });
      }
    };

    fetchUser();
  }, [id, existingImages]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // MODAL
  const openModal = (image) => {
    setSelectedImage(`${config.apiBaseUrl}/${image}`);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage('');
  };

  const deleteImage = async (id, image) => {
    try {
      const splittedImage = image.split("/");
      await axios.delete(`/image/user/${id}/${splittedImage[splittedImage.length-1]}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setExistingImages(existingImages.filter(keepImage => keepImage.url !== image));
      setMsg({ type:"success", text:"Image correctement supprimée." });
    } catch (error) {
      setMsg({ type: "error", text: error?.response?.data?.error ?? "Impossible de supprimer l'image." });
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'avatar') {
      setAvatarFile(files[0]);
    } else if (name === 'banner') {
      setBannerFile(files[0]);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    if (avatarFile) {
      formDataToSend.append('avatar', avatarFile);
    }
    if (bannerFile) {
      formDataToSend.append('banner', bannerFile);
    }

    try {
      await axios.put(`/users/profile/${id}`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      setMsg({ type: "success", text: "Utilisateur correctement mis à jour." });
    } catch (error) {
      setMsg({ type: "error", text: "Impossible de modifier les informations de cet utilisateur." });
    }
  };

  if (!user) {
    return <div id="loading"></div>;
  }

  return (
    <div className="user-details-container">
      <Sidebar />
      <div className="user-details-content">
        <h2>Informations de l'Utilisateur</h2>
        {msg && <Message type={msg.type} message={msg.text} />}
        <form onSubmit={handleFormSubmit} encType="multipart/form-data">
          {existingImages.length > 0 && (
            <div className="existing-images">
              {existingImages.map((image) => (
                <div key={image.url} className='preview-img-container'>
                  <div className='img-button-container'>
                    <button onClick={() => openModal(image.url)}>Voir</button>
                    <button onClick={() => deleteImage(id, image.url)} className='red'>Supprimer</button>
                  </div>
                  <img src={`${config.apiBaseUrl}/${image.url}`} alt={`${image.name}`} height="100" />
                </div>
              ))}
            </div>
          )}
          <label>
            <span>Nom complet :</span>
            <input type="text" name="fullname" value={formData.fullname} onChange={handleInputChange} required />
          </label>
          <label>
            <span>Pseudo :</span>
            <input type="text" name="username" value={formData.username} onChange={handleInputChange} required />
          </label>
          <label>
            <span>Email :</span>
            <input type="email" name="email" value={formData.email} onChange={handleInputChange} required />
          </label>
          <label>
            <span>Description :</span>
            <textarea name="description" value={formData.description} onChange={handleInputChange}></textarea>
          </label>
          <label>
            <span>Role :</span>
            <select name="role" value={formData.role} onChange={handleInputChange}>
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </label>
          <label>
            <span>Status :</span>
            <select name="status" value={formData.status} onChange={handleInputChange}>
              <option value="1">Actif</option>
              <option value="0">Inactif</option>
            </select>
          </label>
          <label>
            <span>Avatar :</span>
            <input type="file" name="avatar" onChange={handleFileChange} />
          </label>
          <label>
            <span>Bannière :</span>
            <input type="file" name="banner" onChange={handleFileChange} />
          </label>
          <button type="submit">Mettre à jour</button>
          <button className='back' onClick={() => navigate('/users')}>Annuler</button>
        </form>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Preview"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={closeModal} className="close-button">Fermer</button>
          <img src={selectedImage} alt="Preview" className="modal-image"/>
        </Modal>
      </div>
    </div>
  );
};

export default UserDetails;
