import axios from 'axios';
import config from './config';
import { useNavigate } from 'react-router-dom';

// Create an instance of axios
const instance = axios.create({
  baseURL: `${config.apiBaseUrl}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Response interceptor
instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      const navigate = useNavigate();
      navigate('/?message=logedout');
    }
    return Promise.reject(error);
  }
);

export default instance;
