import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpenMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const handleLogout = () => {
    localStorage.removeItem('token'); // Supprimer le token
    navigate('/'); // Rediriger vers la page de login
  };

  return (
    <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
      <div onClick={handleOpenMenu} className='button-menu'>MENU</div>
      <h3>Navigation</h3>
      <ul>
        <li><Link className={location.pathname === '/dashboard' ? 'active' : ''} data-name="Tableau de bord" to="/dashboard"></Link></li>
        <li><Link className={location.pathname.indexOf('/users') >= 0 ? 'active' : ''} data-name="Gérer les Utilisateurs" to="/users"></Link></li>
        <li><Link className={location.pathname.indexOf('/spots') >= 0 ? 'active' : ''} data-name="Gérer les Spots" to="/spots"></Link></li>
        <li><Link className={location.pathname.indexOf('/categories') >= 0 ? 'active' : ''} data-name="Gérer les Catégories" to="/categories"></Link></li>
        <li><Link className={location.pathname.indexOf('/trips') >= 0 ? 'active' : ''} data-name="Gérer les Trips" to="/trips"></Link></li>
        <li><button className="logout-button" onClick={handleLogout}>Déconnexion</button></li>
      </ul>
    </div>
  );
};

export default Sidebar;
