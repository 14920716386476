import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import config from '../../config';
import axios from '../../axiosConfig';
import Message from '../Message';
import Sidebar from '../Sidebar';
import './EditTrip.css';

const EditTrip = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    images: [],
    imagePreviews: [],
    videos: [],
    videoPreviews: [],
    steps: [{ title: '', description: '', spots: [], selectedSpots: [] }],
    status: 1,
  });
  const [spots, setSpots] = useState([]);
  const [msg, setMsg] = useState({ type: '', text: '' });
  const [existingImages, setExistingImages] = useState([]);
  const [existingVideos, setExistingVideos] = useState([]);
  // MODAL
  const [selectedMediaType, setSelectedMediaType] = useState("image");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrip = async () => {
      try {
        const response = await axios.get(`/trip/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        const tripData = response.data;
        setFormData({
          title: tripData.title,
          description: tripData.description,
          images: [],
          imagePreviews: tripData.images,
          videos: [],
          videoPreviews: tripData.videos,
          steps: tripData.steps.map(step => ({
            ...step,
            spots: step.spots.map(spot => spot._id),
            selectedSpots: step.spots,
          })),
          status: tripData.status,
        });
        setExistingImages(tripData.images);
        setExistingVideos(tripData.videos);
      } catch (error) {
        animateError('Impossible de récupérer les détails du trip.');
      }
    };

    const fetchSpots = async () => {
      try {
        const response = await axios.get('/spot', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setSpots(response.data);
      } catch (error) {
        animateError('Impossible de récupérer les spots.');
      }
    };

    fetchTrip();
    fetchSpots();
  }, [id]);

  const handleInputChange = (e, index = null) => {
    const { name, value, files } = e.target;
    if (index === null) {
      if (name === 'images' || name === 'videos') {
        const previews = [...files].map(file => URL.createObjectURL(file));
        setFormData({ ...formData, [name]: [...files], [`${name}Previews`]: previews });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      const updatedSteps = [...formData.steps];
      updatedSteps[index][name] = value;
      setFormData({ ...formData, steps: updatedSteps });
    }
  };

  const handleSpotSearch = (e, index) => {
    const { value } = e.target;
    const updatedSteps = [...formData.steps];
    updatedSteps[index].spotSearch = value;
    setFormData({ ...formData, steps: updatedSteps });
  };

  const handleAddSpot = (spot, index) => {
    const updatedSteps = [...formData.steps];
    if (!updatedSteps[index].spots.includes(spot._id)) {
      updatedSteps[index].spots.push(spot._id);
      updatedSteps[index].selectedSpots.push(spot);
      setFormData({ ...formData, steps: updatedSteps });
    }
  };

  const handleRemoveSpot = (spotId, index) => {
    const updatedSteps = [...formData.steps];
    updatedSteps[index].spots = updatedSteps[index].spots.filter(id => id !== spotId);
    updatedSteps[index].selectedSpots = updatedSteps[index].selectedSpots.filter(spot => spot._id !== spotId);
    setFormData({ ...formData, steps: updatedSteps });
  };

  const handleAddStep = () => {
    setFormData({
      ...formData,
      steps: [...formData.steps, { title: '', description: '', spots: [], selectedSpots: [] }],
    });
  };

  const handleRemoveStep = (index) => {
    const updatedSteps = formData.steps.filter((step, i) => i !== index);
    setFormData({ ...formData, steps: updatedSteps });
  };

  const openModal = (image, type) => {
    setSelectedMediaType(type);
    setSelectedMedia(`${config.apiBaseUrl}/${image}`);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedMedia('');
  };

  const animateError = (errMsg) => {
    setMsg({ type: 'error', text: errMsg });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const deleteMedia = async (id, media, type = "image") => {
    try {
      const splittedImage = media.split("/");
      await axios.delete(`/${type}/trip/${id}/${splittedImage[splittedImage.length-1]}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      if (type === "image") {
        setExistingImages(existingImages.filter(keepImage => keepImage !== media));
      } else if (type === "video") {
        setExistingVideos(existingVideos.filter(keepVideo => keepVideo !== media));
      }
      setMsg({ type:"success", text:`Média correctement supprimée.` });
    } catch (error) {
      setMsg({ type: "error", text: error?.response?.data?.error ?? "Impossible de supprimer le Média." });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formData.images.forEach((image, i) => {
      formDataToSend.append(`images`, image);
    });
    formData.videos.forEach((video, i) => {
      formDataToSend.append(`videos`, video);
    });
    formDataToSend.append('status', formData.status);
    formDataToSend.append('steps', JSON.stringify(formData.steps.map(({ selectedSpots, ...step }) => step)));

    try {
      await axios.put(`/trip/${id}`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate('/trips');
    } catch (error) {
      console.error({ error });
      animateError(error?.response?.data?.error ?? 'Impossible de mettre à jour le Trip.');
    }
  };

  return (
    <div className="edit-trip-container">
      <Sidebar />
      <div className="edit-trip-content">
        <h2>Modifier le Trip</h2>
        {msg && <Message type={msg.type} message={msg.text} />}
        <form onSubmit={handleFormSubmit} encType="multipart/form-data">
          <label>
            <span>Titre :</span>
            <input type="text" name="title" value={formData.title} onChange={handleInputChange} required />
          </label>
          <label>
            <span>Description :</span>
            <textarea name="description" value={formData.description} onChange={handleInputChange} required></textarea>
          </label>
          <label>
            <span>Images :</span>
            <input type="file" name="images" multiple onChange={handleInputChange} />
          </label>
          {formData.imagePreviews.map((image, i) => (
              <div key={i} className='preview-img-container'>
                <div className='img-button-container'>
                  <button onClick={() => openModal(image, 'image')}>Voir</button>
                  <button onClick={() => deleteMedia(id, image, 'image')} className='red'>Supprimer</button>
                </div>
                <img src={`${config.apiBaseUrl}${image}`} alt={`Spot ${i}`} height="100" />
              </div>
          ))}
          <label>
            <span>Vidéos :</span>
            <input type="file" name="videos" multiple onChange={handleInputChange} />
          </label>
          {formData.videoPreviews.map((video, i) => (
            <div key={i} className='preview-img-container'>
              <div className='img-button-container'>
                <button onClick={() => openModal(video, 'video')}>Voir</button>
                <button onClick={() => deleteMedia(id, video, 'video')} className='red'>Supprimer</button>
              </div>
              <video key={i} src={video} controls className="video-preview" />
            </div>
          ))}

          {formData.steps.map((step, index) => (
            <div key={index} className="add-step-form">
              <h3>Etape {index + 1}</h3>
              <label>
                <span>Titre :</span>
                <input type="text" name="title" value={step.title} onChange={(e) => handleInputChange(e, index)} required />
              </label>
              <label>
                <span>Description :</span>
                <textarea name="description" value={step.description} onChange={(e) => handleInputChange(e, index)} required></textarea>
              </label>
              <label>
                <span>Search Spots :</span>
                <input type="text" name="spotSearch" value={step.spotSearch || ''} onChange={(e) => handleSpotSearch(e, index)} />
              </label>
              <div className="spots-list">
                {spots
                  .filter(spot => spot.title.toLowerCase().includes(step.spotSearch?.toLowerCase() || ''))
                  .filter(spot => !step.selectedSpots.some(s => s._id === spot._id))
                  .map(filteredSpot => (
                    <div key={filteredSpot._id} onClick={() => handleAddSpot(filteredSpot, index)}>
                      <span>{filteredSpot.title}</span>
                      {filteredSpot.images?.length > 0 && <img src={`${config.apiBaseUrl}/${filteredSpot.images[0]}`} alt={filteredSpot.title} className="spot-image" />}
                    </div>
                  ))}
              </div>
              <div className="selected-spots">
                {step.selectedSpots.map((spot, i) => (
                  <div key={i} className="selected-spot">
                    {spot.images?.length > 0 && <img src={`${config.apiBaseUrl}/${spot.images[0]}`} alt={spot.title} className="spot-image" />}
                    <span>{spot.title}</span>
                    <button type="button" onClick={() => handleRemoveSpot(spot._id, index)}>X</button>
                  </div>
                ))}
              </div>
              <div>
                <button type="button" onClick={() => handleRemoveStep(index)} className="remove-step-button red">Supprimer Etape</button>
              </div>
            </div>
          ))}
          <button className='step-button' type="button" onClick={handleAddStep}>Ajouter une Etape</button>
          <button type="submit">Mettre à jour le Trip</button>
        </form>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Preview"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={closeModal} className="close-button">Fermer</button>
          {selectedMediaType === "image" && <img src={selectedMedia} alt="Preview" className="modal-image"/>}
          {selectedMediaType === "video" && <video src={selectedMedia} controls className="modal-video" />}
        </Modal>
      </div>
    </div>
  );
};

export default EditTrip;
