import React, { useState, useEffect } from 'react';
import * as jwtDecode from 'jwt-decode';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from './ErrorMessage';
import config from '../config';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.apiBaseUrl}/auth/login`, { email, password });
      const token = response.data?.token;
      if (!token) throw new Error("Token is missing");
      const decodedToken = jwtDecode.jwtDecode(token);
      if (decodedToken.role !== "admin") throw new Error("You are not Administrator");
      localStorage.setItem('token', token);
      navigate('/dashboard');
    } catch (error) {
      setError(error.message ?? 'Invalid credentials. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <img className="logo" src="../logo192.png" alt="Logo" />
      <div className="box">
        <h2>Connexion</h2>
        {error && <ErrorMessage message={error} />}
        <form onSubmit={handleLogin}>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required autoComplete="email" />
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required autoComplete="current-password" />
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
