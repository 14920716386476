import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import ErrorMessage from '../ErrorMessage';
import Sidebar from '../Sidebar';
import config from '../../config';
import Modal from 'react-modal';
import './TripList.css';

Modal.setAppElement('#root');

const TripList = () => {
  const [trips, setTrips] = useState([]);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  // MODAL
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  // SORT AND FILTER
  const [tri, setTri] = useState('');
  const [triSort, setTriSort] = useState('asc');
  const [filters, setFilters] = useState({
    search: '',
    statusFilters: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const buildQueryParams = () => {
      const params = new URLSearchParams();
      if (tri && triSort) {
        params.append('tri', tri);
        params.append('triSort', triSort);
      }
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          params.append(key, filters[key]);
        }
      });
      return params.toString();
    };
    const fetchTrips = async () => {
      try {
        const queryParams = buildQueryParams();
        const response = await axios.get(`/trip?${queryParams}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setTrips(response.data);
      } catch (error) {
        setError('Impossible de récpérer les Trips.');
      }
    };

    fetchTrips();
  }, [tri, triSort, filters]);

  // SORT AND FILTERS
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleTri = async (triName) => {
    setTri(triName);
    setTriSort(triSort === "asc" ? "desc" : "asc");
  };

  const openModal = (image) => {
    setSelectedImage(`${config.apiBaseUrl}/${image}`);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage('');
  };

  const confirmDelete = async (id) => {
    if (window.confirm("Voulez-vous supprimer ce trip ?")) {
      await handleDelete(id);
    }
  }

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/trip/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTrips(trips.filter(trip => trip._id !== id));
    } catch (error) {
      setError('Impossible de supprimer ce Trip.');
    }
  };

  return (
    <div className="trips-container">
      <Sidebar />
      <div className="trips-content">
        <h2>Gérer les Trips</h2>
        {error && <ErrorMessage message={error} />}
        <Link to="/trips/add" className="add-trip-button">Ajouter un Trip</Link>
        <h3>Filtres</h3>
        <div id="filters">
          <form className='filters-container'>
            <label className='filters'>
              <span>Recherche</span>
              <input
                type="text"
                name="search"
                placeholder="Recherche"
                value={filters.search}
                onChange={handleFilterChange}
              />
            </label>
            <label className='filters'>
              <span>Status</span>
              <select onChange={handleFilterChange} name="statusFilters">
                <option value="all">Tous</option>
                <option value="active">Actif</option>
                <option value="inactive">Inactif</option>
              </select>
            </label>
          </form>
        </div>
        <table className="table sortable">
          <thead>
            <tr>
                <th className={`${tri === 'title' ? triSort : ''}`} onClick={() => handleTri('title')}>Titre</th>
                <th className={`${tri === 'author' ? triSort : ''}`} onClick={() => handleTri('author')}>Auteur</th>
                <th className={`${tri === 'status' ? triSort : ''}`} onClick={() => handleTri('status')}>Status</th>
                <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {trips.map(trip => (
                <tr key={trip._id}>
                    <td><h3>{trip.title}</h3></td>
                    <td>{trip.author.fullname}</td>
                    <td className="center">{trip.status === 1 ? <img height="48" src="/done.png" alt="Active" /> : <img height="48" src="/hidden.png" alt="Inactive" />}</td>
                    <td className='text-right'>
                        <button onClick={() => navigate(`/trips/${trip._id}`)}>Modifier</button>
                        <button className='red' onClick={() => confirmDelete(trip._id)}>Supprimer</button>
                    </td>
                </tr>
            ))}
          </tbody>
        </table>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Preview"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={closeModal} className="close-button">Fermer</button>
          <img src={selectedImage} alt="Preview" className="modal-image"/>
        </Modal>
      </div>
    </div>
  );
};

export default TripList;
