import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import axios from '../../axiosConfig';
import Message from '../Message';
import Sidebar from '../Sidebar';
import './AddTrip.css';

const AddTrip = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    images: [],
    videos: [],
    steps: [{ title: '', description: '', spots: [], selectedSpots: [] }],
    status: 1,
  });
  const [spots, setSpots] = useState([]);
  const [msg, setMsg] = useState({ type: '', text: '' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSpots = async () => {
      try {
        const response = await axios.get('/spot', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setSpots(response.data);
      } catch (error) {
        animateError('Impossible de récupérer les spots.');
      }
    };

    fetchSpots();
  }, []);

  const animateError = (errMsg) => {
    setMsg({ type: 'error', text: errMsg });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleInputChange = (e, index = null) => {
    const { name, value, files } = e.target;
    if (index === null) {
      if (name === 'images' || name === 'videos') {
        setFormData({ ...formData, [name]: [...files] });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      const updatedSteps = [...formData.steps];
      if (name === 'spots') {
        updatedSteps[index][name] = value.split(',').map(id => id.trim());
      } else {
        updatedSteps[index][name] = value;
      }
      setFormData({ ...formData, steps: updatedSteps });
    }
  };

  const handleSpotSearch = (e, index) => {
    const { value } = e.target;
    const updatedSteps = [...formData.steps];
    updatedSteps[index].spotSearch = value;
    setFormData({ ...formData, steps: updatedSteps });
  };

  const handleAddSpot = (spot, index) => {
    const updatedSteps = [...formData.steps];
    if (!updatedSteps[index].spots.includes(spot._id)) {
      updatedSteps[index].spots.push(spot._id);
      updatedSteps[index].selectedSpots.push(spot);
      setFormData({ ...formData, steps: updatedSteps });
    }
  };

  const handleRemoveSpot = (spotId, index) => {
    const updatedSteps = [...formData.steps];
    updatedSteps[index].spots = updatedSteps[index].spots.filter(id => id !== spotId);
    updatedSteps[index].selectedSpots = updatedSteps[index].selectedSpots.filter(spot => spot._id !== spotId);
    setFormData({ ...formData, steps: updatedSteps });
  };

  const handleAddStep = () => {
    setFormData({
      ...formData,
      steps: [...formData.steps, { title: '', description: '', spots: [], selectedSpots: [] }],
    });
  };

  const handleRemoveStep = (index) => {
    const updatedSteps = formData.steps.filter((step, i) => i !== index);
    setFormData({ ...formData, steps: updatedSteps });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formData.images.forEach((photo, i) => {
      formDataToSend.append(`images`, photo);
    });
    formData.videos.forEach((video, i) => {
      formDataToSend.append(`videos`, video);
    });
    formDataToSend.append('status', formData.status);
    formDataToSend.append('steps', JSON.stringify(formData.steps.map(({ selectedSpots, ...step }) => step)));

    try {
      await axios.post('/trip', formDataToSend, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate('/trips');
    } catch (error) {
      console.error({ error });
      animateError(error?.response?.data?.error ?? 'Impossible d\'ajouter un Trip.');
    }
  };

  return (
    <div className="add-trip-container">
      <Sidebar />
      <div className="add-trip-content">
        <h2>Ajouter un Trip</h2>
        {msg && <Message type={msg.type} message={msg.text} />}
        <form onSubmit={handleFormSubmit} encType="multipart/form-data">
          <label>
            <span>Titre :</span>
            <input type="text" name="title" value={formData.title} onChange={handleInputChange} required />
          </label>
          <label>
            <span>Description :</span>
            <textarea name="description" value={formData.description} onChange={handleInputChange} required></textarea>
          </label>
          <label>
            <span>Photos :</span>
            <input type="file" name="images" multiple onChange={handleInputChange} />
          </label>
          <label>
            <span>Vidéos :</span>
            <input type="file" name="videos" multiple onChange={handleInputChange} />
          </label>

          {formData.steps.map((step, index) => (
            <div key={index} className="add-step-form">
              <h3>Etape {index + 1}</h3>
              <label>
                <span>Titre :</span>
                <input type="text" name="title" value={step.title} onChange={(e) => handleInputChange(e, index)} required />
              </label>
              <label>
                <span>Description :</span>
                <textarea name="description" value={step.description} onChange={(e) => handleInputChange(e, index)} required></textarea>
              </label>
              <label>
                <span>Search Spots :</span>
                <input type="text" name="spotSearch" value={step.spotSearch || ''} onChange={(e) => handleSpotSearch(e, index)} />
              </label>
              <div className="spots-list">
                {spots
                  .filter(spot => spot.title.toLowerCase().includes(step.spotSearch?.toLowerCase() || ''))
                  .filter(spot => !step.selectedSpots.some(s => s._id === spot._id))
                  .map(filteredSpot => (
                    <div key={filteredSpot._id} onClick={() => handleAddSpot(filteredSpot, index)}>
                      <span>{filteredSpot.title}</span>
                      {filteredSpot.images.length > 0 && <img src={`${config.apiBaseUrl}/${filteredSpot.images[0]}`} alt={filteredSpot.title} className="spot-image" />}
                    </div>
                  ))}
              </div>
              <div className="selected-spots">
                {step.selectedSpots.map((spot, i) => (
                  <div key={i} className="selected-spot">
                    {spot.images.length > 0 && <img src={`${config.apiBaseUrl}/${spot.images[0]}`} alt={spot.title} className="spot-image" />}
                    <span>{spot.title}</span>
                    <button type="button" onClick={() => handleRemoveSpot(spot._id, index)}>X</button>
                  </div>
                ))}
              </div>
              <div>
                <button type="button" onClick={() => handleRemoveStep(index)} className="remove-step-button red">Supprimer Etape</button>
              </div>
            </div>
          ))}
          <button className='step-button' type="button" onClick={handleAddStep}>Ajouter une Etape</button>
          <button type="submit">Ajouter un Trip</button>
        </form>
      </div>
    </div>
  );
};

export default AddTrip;
