import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import ErrorMessage from '../ErrorMessage';
import Sidebar from '../Sidebar';
import config from '../../config';
import Modal from 'react-modal';
import './SpotList.css';

Modal.setAppElement('#root');

const SpotList = () => {
  const [spots, setSpots] = useState([]);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  // MODAL
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  // SORT AND FILTER
  const [tri, setTri] = useState('');
  const [triSort, setTriSort] = useState('asc');
  const [filters, setFilters] = useState({
    search: '',
    imageFilters: '',
    categoriesFilters: '',
    statusFilters: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const buildQueryParams = () => {
      const params = new URLSearchParams();
      if (tri && triSort) {
        params.append('tri', tri);
        params.append('triSort', triSort);
      }
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          params.append(key, filters[key]);
        }
      });
      return params.toString();
    };
    const fetchSpots = async () => {
      try {
        const queryParams = buildQueryParams();
        const response = await axios.get(`/spot?${queryParams}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setSpots(response.data);
      } catch (error) {
        setError('Impossible de récpérer les Spots.');
      }
    };
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`/category?isParentFilters=true`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCategories(response.data);
      } catch (error) {
        setError('Impossible de récpérer les Spots.');
      }
    };

    fetchSpots();
    fetchCategories();
  }, [tri, triSort, filters]);

  // SORT AND FILTERS
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleTri = async (triName) => {
    setTri(triName);
    setTriSort(triSort === "asc" ? "desc" : "asc");
  };

  const openModal = (image) => {
    setSelectedImage(`${config.apiBaseUrl}/${image}`);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage('');
  };

  const confirmDelete = async (id) => {
    if (window.confirm("Voulez-vous supprimer ce spot ?")) {
      await handleDelete(id);
    }
  }

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/spot/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setSpots(spots.filter(spot => spot._id !== id));
    } catch (error) {
      setError('Impossible de supprimer ce Spot.');
    }
  };

  return (
    <div className="spots-container">
      <Sidebar />
      <div className="spots-content">
        <h2>Gérer les Spots</h2>
        {error && <ErrorMessage message={error} />}
        <Link to="/spots/add" className="add-spot-button">Ajouter un Spot</Link>
        <h3>Filtres</h3>
        <div id="filters">
          <form className='filters-container'>
            <label className='filters'>
              <span>Recherche</span>
              <input
                type="text"
                name="search"
                placeholder="Recherche"
                value={filters.search}
                onChange={handleFilterChange}
              />
            </label>
            <label className='filters'>
              <span>Image</span>
              <select onChange={handleFilterChange} name="imageFilters">
                <option value="all">Tous</option>
                <option value="withimage">Avec Image</option>
                <option value="noimage">Sans Image</option>
              </select>
            </label>
            {categories && <label className='filters'>
              <span>Catégories</span>
              <select onChange={handleFilterChange} name="categoryFilters">
                <option value="all">Tous</option>
                {categories.map((category, index) => <option key={index} value={category._id}>{category.titre}</option>)}
              </select>
            </label>}
            <label className='filters'>
              <span>Status</span>
              <select onChange={handleFilterChange} name="statusFilters">
                <option value="all">Tous</option>
                <option value="active">Actif</option>
                <option value="inactive">Inactif</option>
              </select>
            </label>
          </form>
        </div>
        <table className="table sortable">
          <thead>
            <tr>
                <th className={`${tri === 'title' ? triSort : ''}`} onClick={() => handleTri('title')}>Titre</th>
                <th className={`${tri === 'city' ? triSort : ''}`} onClick={() => handleTri('city')}>Ville</th>
                <th className={`${tri === 'country' ? triSort : ''}`} onClick={() => handleTri('country')}>Country</th>
                <th className={`${tri === 'image' ? triSort : ''}`} onClick={() => handleTri('image')}>Image</th>
                <th className={`${tri === 'status' ? triSort : ''}`} onClick={() => handleTri('status')}>Status</th>
                <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {spots.map(spot => (
                <tr key={spot._id}>
                    <td><h3>{spot.title}</h3></td>
                    <td>{spot.city}</td>
                    <td>{spot.country}</td>
                    <td className="center">
                        <div className='image-preview'>
                            {spot.images.length > 0 && 
                                <img 
                                    src={`${config.apiBaseUrl}/${spot.images[0]}`} 
                                    alt={spot.title} 
                                    onClick={() => openModal(spot.images[0])} 
                                    style={{ cursor: 'pointer' }}
                                    height="100"
                                />
                            }
                        </div>
                    </td>
                    <td className="center">{spot.status === 1 ? <img height="48" src="/done.png" alt="Active" /> : <img height="48" src="/hidden.png" alt="Inactive" />}</td>
                    <td className='text-right'>
                        <button onClick={() => navigate(`/spots/${spot._id}`)}>Modifier</button>
                        <button className='red' onClick={() => confirmDelete(spot._id)}>Supprimer</button>
                    </td>
                </tr>
            ))}
          </tbody>
        </table>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Preview"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={closeModal} className="close-button">Fermer</button>
          <img src={selectedImage} alt="Preview" className="modal-image"/>
        </Modal>
      </div>
    </div>
  );
};

export default SpotList;
