import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Sidebar from './Sidebar';
import ErrorMessage from './ErrorMessage';
import './Dashboard.css';

const Dashboard = () => {
  const [stats, setStats] = useState({
    userCount: 0,
    spotCount: 0,
    categoryCount: 0,
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('/stats', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setStats(response.data);
      } catch (error) {
        setError('Failed to fetch statistics.');
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <h2>Tableau de bord</h2>
        {error && <ErrorMessage message={error} />}
        <div className="stats-grid">
          <div className="stat-card">
            <h3>Utilisateurs</h3>
            <p>{stats.userCount}</p>
          </div>
          <div className="stat-card">
            <h3>Spots</h3>
            <p>{stats.spotCount}</p>
          </div>
          <div className="stat-card">
            <h3>Catégories</h3>
            <p>{stats.categoryCount}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
