import React from 'react';
import './Message.css';

const Message = ({ type, message }) => {

    const cls = `${type}-message`;
    return (
        <div className={cls}>
            {message}
        </div>
    );
};

export default Message;
