import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Users from './components/User/Users';
import UserDetails from './components/User/UserDetails';
import SpotList from './components/Spot/SpotList';
import AddSpot from './components/Spot/AddSpot';
import EditSpot from './components/Spot/EditSpot';
import CategoryList from './components/Category/CategoryList';
import AddCategory from './components/Category/AddCategory';
import EditCategory from './components/Category/EditCategory';
import TripList from './components/Trip/TripList';
import AddTrip from './components/Trip/AddTrip';
import EditTrip from './components/Trip/EditTrip';
import PrivateRoute from './components/PrivateRoute';
import './styles.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:id" element={<UserDetails />} />
          <Route path="/spots" element={<SpotList />} />
          <Route path="/spots/add" element={<AddSpot />} />
          <Route path="/spots/:id" element={<EditSpot />} />
          <Route path="/categories" element={<CategoryList />} />
          <Route path="/categories/add" element={<AddCategory />} />
          <Route path="/categories/:id" element={<EditCategory />} />
          <Route path="/trips" element={<TripList />} />
          <Route path="/trips/add" element={<AddTrip />} />
          <Route path="/trips/:id" element={<EditTrip />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
