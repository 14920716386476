import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import axios from '../../axiosConfig';
import Message from '../Message';
import Sidebar from '../Sidebar';
import SearchableSelect from '../SearchableSelect';
import config from '../../config';
import './EditCategory.css';

Modal.setAppElement('#root');

const EditCategory = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    titre: '',
    undercat: [],
    image: null,
    icon: null,
    status: 1,
  });
  const [msg, setMsg] = useState({ type: '', text: '' });
  const [categories, setCategories] = useState([]);
  const [existingImage, setExistingImage] = useState(null);
  // MODAL
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`/category/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setFormData({
          titre: response.data.titre,
          undercat: response.data.undercat,
          image: response.data.image,
          icon: response.data.icon,
          status: response.data.status
        });
        const existingImageTmp = [];
        if (response.data.image) {
          existingImageTmp.push(response.data.image);
        }
        if (response.data.icon) {
          existingImageTmp.push(response.data.icon);
        }
        if (existingImageTmp.length) {
          setExistingImage(existingImageTmp);
        }
      } catch (error) {
        setMsg({ type: "error", text: 'Impossible de récupérer les informations du Category.' });
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get('/category?isParentFilters=false', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCategories(response.data.filter(categoryElem => categoryElem._id !== id));
      } catch (error) {
        setMsg({ type: "error", text: 'Impossible de récupérer les Categories.' });
      }
    };

    fetchCategories();
    fetchCategory();
  }, [id]);

  const deleteImage = async (id, image) => {
    try {
      const splittedImage = image.split("/");
      await axios.delete(`/image/category/${id}/${splittedImage[splittedImage.length-1]}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setExistingImage(existingImage.filter(keepImage => keepImage !== image));
      setMsg({ type:"success", text:"Image correctement supprimée." });
    } catch (error) {
      setMsg({ type: "error", text: error?.response?.data?.error ?? "Impossible de supprimer l'image." });
    }
  };

  const openModal = (image) => {
    setSelectedImage(`${config.apiBaseUrl}/${image}`);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage('');
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      setFormData({ ...formData, [name]: files[0] });
    } else if (name === 'icon') {
      setFormData({ ...formData, [name]: files[0] });
    } else if (name === 'undercat') {
      setFormData({ ...formData, undercat: [...e.target.selectedOptions].map(option => option.value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (['undercat'].includes(key)) {
        formDataToSend.append(key, formData[key].join(','));
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }
    try {
        await axios.put(`/category/${id}`, formDataToSend, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
            },
        });
        navigate('/categories');
    } catch (error) {
        setMsg({ type: "error", text: error?.response?.data?.error ?? 'Impossible de mettre à jour le Category.' });
    }
  };

  return (
    <div className="add-category-container">
      <Sidebar />
      <div className="add-category-content">
        <h2>Modifier la Catégorie</h2>
        {msg && <Message type={msg.type} message={msg.text} />}
        {existingImage && (
          <div className="existing-images">
              <div className='preview-img-container'>
                <div className='img-button-container'>
                  <button onClick={() => openModal(existingImage)}>Voir</button>
                  <button onClick={() => deleteImage(id, existingImage)} className='red'>Supprimer</button>
                </div>
                <img src={`${config.apiBaseUrl}/${existingImage}`} alt={`Category`} height="100" />
              </div>
          </div>
        )}
        <form onSubmit={handleFormSubmit} encType="multipart/form-data">
          <label>
            <span>Titre :</span>
            <input type="text" name="titre" value={formData.titre} onChange={handleInputChange} required />
          </label>
          <label>
            <span>Sous-Catégorie :</span>
            <SearchableSelect
              multiple={true}
              selectName="undercat"
              elements={categories}
              formData={formData}
              setFormData={setFormData}
            />
          </label>
          <label>
            <span>Image :</span>
            <input type="file" name="image" onChange={handleInputChange} />
          </label>
          <label>
            <span>Icon :</span>
            <input type="file" name="Icon" onChange={handleInputChange} />
          </label>
          <label>
            <span>Status :</span>
            <select name="status" value={formData.status} onChange={handleInputChange}>
              <option value={1}>Actif</option>
              <option value={0}>Inactif</option>
            </select>
          </label>
          <button type="submit">Mettre à jour</button>
          <button className='back' onClick={() => navigate('/categories')}>Annuler</button>
        </form>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Preview"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={closeModal} className="close-button">Fermer</button>
          <img src={selectedImage} alt="Preview" className="modal-image"/>
        </Modal>
      </div>
    </div>
  );
};

export default EditCategory;
