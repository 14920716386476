import React, { useState, useCallback, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker, MarkerF, Autocomplete } from '@react-google-maps/api';
import './MapPicker.css';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 48.9000059,
  lng: 2.3105132
};

const MapPicker = ({ initialLocation, onLocationSelect }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBvjF-MDl-Xjgtg-6ZGMIVNNrkmLcKY03Q',
    libraries,
  });
  const [marker, setMarker] = useState({ lat: initialLocation.lat, lng: initialLocation.lng });
  const [autocomplete, setAutocomplete] = useState(null);
  const mapRef = useRef();

  const onMapClick = useCallback((event) => {
    console.log("click triggerd ?");
    const newLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarker(newLocation);
    fetchLocationDetails(newLocation);
  }, []);

  const fetchLocationDetails = (location) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const addressComponents = results[0].address_components;
        const address = results[0].formatted_address;
        const city = addressComponents.find(component => component.types.includes('locality'))?.long_name || '';
        const country = addressComponents.find(component => component.types.includes('country'))?.long_name || '';

        onLocationSelect({ ...location, address, city, country });
      }
    });
  };

  const onLoad = useCallback((map) => {
    mapRef.current = map;
    mapRef.current.panTo(marker);
  }, [marker]);

  const onPlaceChanged = () => {
    if (!autocomplete) return;
    const place = autocomplete.getPlace();
    if (place && place.geometry) {
      const newLocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setMarker(newLocation);
      fetchLocationDetails(newLocation);
      mapRef.current.panTo(newLocation);
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <div className='google-map'>
      <Autocomplete className='autocomplete-google' onLoad={setAutocomplete} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          placeholder="Rechercher un lieu"
          style={{
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '100%',
            height: '32px',
            padding: '0 12px',
            borderRadius: '3px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            outline: 'none',
            textOverflow: 'ellipses',
          }}
        />
      </Autocomplete>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={center}
        onClick={onMapClick}
        onLoad={onLoad}
      >
        <MarkerF
          position={marker}
          draggable
          icon={{
            url: "../pin.png",
            scaledSize: new window.google.maps.Size(50, 50),
          }}
          onDragEnd={(event) => {
            const newLocation = {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            };
            setMarker(newLocation);
            fetchLocationDetails(newLocation);
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default MapPicker;
