import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from '../../axiosConfig';
import Message from '../Message';
import Sidebar from '../Sidebar';
import config from '../../config';
import Modal from 'react-modal';
import './CategoryList.css';

Modal.setAppElement('#root');

const CategoryList = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [msg, setMsg] = useState({ type: '', text: '' });
  // MODAL
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  // SORT AND FILTER
  const [tri, setTri] = useState('');
  const [triSort, setTriSort] = useState('asc');
  const [filters, setFilters] = useState({
    search: '',
    undercatFilters: '',
    imageFilters: '',
    statusFilters: '',
  });

  useEffect(() => {
    const buildQueryParams = () => {
      const params = new URLSearchParams();
      if (tri && triSort) {
        params.append('tri', tri);
        params.append('triSort', triSort);
      }
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          params.append(key, filters[key]);
        }
      });
      return params.toString();
    };
    const fetchCategories = async () => {
      try {
        const queryParams = buildQueryParams();
        const response = await axios.get(`/category?${queryParams}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCategories(response.data);
      } catch (error) {
        setMsg({ type: "error", text: 'Impossible de récupérer les Categories.' });
      }
    };

    fetchCategories();
  }, [tri, triSort, filters]);

  // SORT AND FILTERS
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleTri = async (triName) => {
    setTri(triName);
    setTriSort(triSort === "asc" ? "desc" : "asc");
  };

  // MODAL
  const openModal = (image) => {
    setSelectedImage(`${config.apiBaseUrl}/${image}`);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage('');
  };

  const confirmDelete = async (id) => {
    if (window.confirm("Voulez-vous supprimer ce Category ?")) {
      await handleDelete(id);
    }
  }

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/category/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCategories(categories.filter(category => category._id !== id));
    } catch (error) {
      setMsg({ type: "error", text: 'Impossible de supprimer ce Category.' });
    }
  };

  return (
    <div className="categories-container">
      <Sidebar />
      <div className="categories-content">
        <h2>Gérer les Categories</h2>
        {msg && <Message type={msg.type} message={msg.text} />}
        <Link to="/categories/add" className="add-category-button">Ajouter une Catégorie</Link>
        <h3>Filtres</h3>
        <div id="filters">
          <form className='filters-container'>
            <label className='filters'>
              <span>Recherche</span>
              <input
                type="text"
                name="search"
                placeholder="Recherche"
                value={filters.search}
                onChange={handleFilterChange}
              />
            </label>
            <label className='filters'>
              <span>Sous-Catégorie</span>
              <select onChange={handleFilterChange} name="isParentFilters">
                <option value="all">Tous</option>
                <option value="true">Catégorie parente</option>
                <option value="false">Catégorie sans enfant</option>
              </select>
            </label>
            <label className='filters'>
              <span>Image</span>
              <select onChange={handleFilterChange} name="imageFilters">
                <option value="all">Tous</option>
                <option value="withimage">Avec Image</option>
                <option value="noimage">Sans Image</option>
              </select>
            </label>
            <label className='filters'>
              <span>Icon</span>
              <select onChange={handleFilterChange} name="iconFilters">
                <option value="all">Tous</option>
                <option value="withimage">Avec Image</option>
                <option value="noimage">Sans Image</option>
              </select>
            </label>
            <label className='filters'>
              <span>Status</span>
              <select onChange={handleFilterChange} name="statusFilters">
                <option value="all">Tous</option>
                <option value="active">Actif</option>
                <option value="inactive">Inactif</option>
              </select>
            </label>
          </form>
        </div>
        <table className="table sortable">
          <thead>
            <tr>
              <th className={`${tri === 'titre' ? triSort : ''}`} onClick={() => handleTri('titre')}>Titre</th>
              <th className={`${tri === 'undercat' ? triSort : ''}`} onClick={() => handleTri('undercat')}>Sous-Catégorie</th>
              <th className={`${tri === 'image' ? triSort : ''}`} onClick={() => handleTri('image')}>Image</th>
              <th className={`${tri === 'icon' ? triSort : ''}`} onClick={() => handleTri('icon')}>Icon</th>
              <th className={`${tri === 'status' ? triSort : ''}`} onClick={() => handleTri('status')}>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {categories.map(category => (
                <tr key={category._id}>
                    <td><h3>{category.titre}</h3></td>
                    <td>{category.undercat.map(undercat => undercat.titre).join(", ")}</td>
                    <td className="center">
                        {category.image && <div className='image-preview'>
                            <img 
                                src={`${config.apiBaseUrl}/${category.image}`} 
                                alt={category.titre} 
                                onClick={() => openModal(category.image)} 
                                style={{ cursor: 'pointer' }}
                                height="100"
                            />
                        </div>}
                    </td>
                    <td className="center">
                        {category.icon && <div className='image-preview'>
                            <img 
                                src={`${config.apiBaseUrl}/${category.icon}`} 
                                alt={category.titre} 
                                onClick={() => openModal(category.icon)} 
                                style={{ cursor: 'pointer' }}
                                height="100"
                            />
                        </div>}
                    </td>
                    <td className="center">{category.status === 1 ? <img height="48" src="/done.png" alt="Active" /> : <img height="48" src="/hidden.png" alt="Inactive" />}</td>
                    <td className='text-right'>
                      <button onClick={() => navigate(`/categories/${category._id}`)}>Modifier</button>
                      <button className='red' onClick={() => confirmDelete(category._id)}>Supprimer</button>
                    </td>
                </tr>
            ))}
          </tbody>
        </table>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Preview"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={closeModal} className="close-button">Fermer</button>
          <img src={selectedImage} alt="Preview" className="modal-image"/>
        </Modal>
      </div>
    </div>
  );
};

export default CategoryList;
