import React, { useState, useEffect } from 'react';
import './SearchableSelect.css';

const SearchableSelect = ({ selectName, elements, formData, setFormData }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredElements, setFilteredElements] = useState(elements);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredElements(elements);
    } else {
      setFilteredElements(elements.filter(element => element.titre.toLowerCase().includes(searchTerm.toLowerCase())));
    }
  }, [searchTerm, elements]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    let updatedValues = [...formData[name]];
    if (checked) {
      updatedValues.push(value);
    } else {
      updatedValues = updatedValues.filter(item => item !== value);
    }
    setSearchTerm('');
    setFormData({
      ...formData,
      [name]: updatedValues,
    });
  };

  return (
    <div className='searchable-select'>
      <input
        type="text"
        name="search"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search..."
      />
      <div className="checkbox-list">
        {filteredElements.map(element => (
          <label htmlFor={element._id} key={element._id} className="checkbox-item">
            <input
              type="checkbox"
              id={element._id}
              name={selectName}
              value={element._id}
              checked={formData[selectName].includes(element._id)}
              onChange={handleCheckboxChange}
            />
            <span>{element.titre}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default SearchableSelect;
