import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import Message from '../Message';
import Sidebar from '../Sidebar';
import SearchableSelect from '../SearchableSelect';
import './AddCategory.css';

const AddCategory = () => {
  const [formData, setFormData] = useState({
    titre: '',
    undercat: [],
    image: null,
    icon: null,
    status: 1,
  });
  const [msg, setMsg] = useState({ type: '', text: '' });
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/category?isParentFilters=false', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCategories(response.data);
      } catch (error) {
        setMsg({ type: "error", text: 'Impossible de récupérer les Categories.' });
      }
    };

    fetchCategories();
  }, []);


  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      setFormData({ ...formData, [name]: files[0] });
    }  else if (name === 'icon') {
      setFormData({ ...formData, [name]: files[0] });
    } else if (name === 'undercat') {
      setFormData({ ...formData, undercat: [...e.target.selectedOptions].map(option => option.value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (['undercat'].includes(key)) {
        formDataToSend.append(key, formData[key].join(','));
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    try {
        await axios.post('/category', formDataToSend, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
            },
        });
        navigate('/categories');
    } catch (error) {
        setMsg({ type: "error", text: error?.response?.data?.error ?? 'Impossible d\'ajouter un Category.' });
    }
  };

  return (
    <div className="add-category-container">
      <Sidebar />
      <div className="add-category-content">
        <h2>Ajouter une Catégorie</h2>
        {msg && <Message type={msg.type} message={msg.text} />}
        <form onSubmit={handleFormSubmit} encType="multipart/form-data">
          <label>
            <span>Titre :</span>
            <input type="text" name="titre" value={formData.titre} onChange={handleInputChange} required />
          </label>
          <label>
            <span>Sous-Catégories :</span>
            <SearchableSelect multiple={true} selectName="undercat" elements={categories} formData={formData} setFormData={setFormData} />
          </label>
          <label>
            <span>Image :</span>
            <input type="file" name="image" onChange={handleInputChange} />
          </label>
          <label>
            <span>Icon :</span>
            <input type="file" name="icon" onChange={handleInputChange} />
          </label>
          <label>
            <span>Status :</span>
            <select name="status" value={formData.status} onChange={handleInputChange}>
              <option value={1}>Actif</option>
              <option value={0}>Inactif</option>
            </select>
          </label>
          <button type="submit">Ajouter un Category</button>
          <button className='back' onClick={() => navigate('/categories')}>Annuler</button>
        </form>
      </div>
    </div>
  );
};

export default AddCategory;
