import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import ErrorMessage from '../ErrorMessage';
import Sidebar from '../Sidebar';
import MapPicker from '../MapPicker';
import SearchableSelect from '../SearchableSelect';
import './AddSpot.css';

const AddSpot = () => {
  const [formData, setFormData] = useState({
    title: '',
    address: '',
    city: '',
    country: '',
    description: '',
    latitude: 48.9000059,
    longitude: 2.3105132,
    images: [],
    status: 1,
    categories: [],
  });
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [onlyDev, setOnlyDev] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/category?isParentFilters=false', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCategories(response.data);
      } catch (error) {
        animateError('Impossible de récupérer les Catégories.');
      }
    };
    fetchCategories();
  }, []);

  const handleDevClick = () => {
    setOnlyDev(!onlyDev);
  }

  const animateError = (errMsg) => {
    setError(errMsg);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'images') {
      setFormData({ ...formData, [name]: [...files] });
    } else if (name === 'categories') {
      setFormData({ ...formData, categories: [...e.target.selectedOptions].map(option => option.value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleLocationSelect = (location) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      latitude: location.lat,
      longitude: location.lng,
      city: location.city,
      country: location.country,
      address: location.address ?? ''
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData['categories'].length === 0) {
      animateError('Vous devez choisir au moins une catégorie.');
      return;
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      if (key === 'images') {
        formData[key].forEach((file) => {
          formDataToSend.append('images', file);
        });
      } else if (['categories'].includes(key)) {
        formDataToSend.append(key, formData[key].join(','));
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    try {
      await axios.post('/spot', formDataToSend, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate('/spots');
    } catch (error) {
      console.error({ error });
      animateError(error?.response?.data?.error ?? 'Impossible d\'ajouter un Spot.');
    }
  };

  return (
    <div className="add-spot-container">
      <Sidebar />
      <div className="add-spot-content">
        <div className='top-header'>
          <h2>Ajouter un Spot</h2>
          <button className={onlyDev ? 'active' : 'inactive'} onClick={handleDevClick}>MODE Développeur: {onlyDev ? 'actif' : 'inactif'}</button>
        </div>
        {error && <ErrorMessage message={error} />}
        <form onSubmit={handleFormSubmit} encType="multipart/form-data">
          <MapPicker initialLocation={{ lat: formData.latitude, lng: formData.longitude }} onLocationSelect={handleLocationSelect} />
          {onlyDev && <div className='onlyDev'>
            <label>
              <span>Pays :</span>
              <input type="text" name="country" value={formData.country} onChange={handleInputChange} required />
            </label>
            <label>
              <span>Ville :</span>
              <input type="text" name="city" value={formData.city} onChange={handleInputChange} required />
            </label>
            <label>
              <span>Adresse :</span>
              <input type="text" name="address" value={formData.address} onChange={handleInputChange} required />
            </label>
            <label>
              <span>Latitude :</span>
              <input type="text" name="latitude" value={formData.latitude} onChange={handleInputChange} required />
            </label>
            <label>
              <span>Longitude :</span>
              <input type="text" name="longitude" value={formData.longitude} onChange={handleInputChange} required />
            </label>
          </div>}
          <label>
            <span>Titre :</span>
            <input type="text" name="title" value={formData.title} onChange={handleInputChange} required />
          </label>
          <label>
            <span>Description :</span>
            <textarea name="description" value={formData.description} onChange={handleInputChange} required></textarea>
          </label>
          <label>
            <span>Images :</span>
            <input type="file" name="images" onChange={handleInputChange} multiple />
          </label>
          <label>
            <span>Catégories :</span>
            <SearchableSelect selectName="categories" elements={categories} formData={formData} setFormData={setFormData} />
          </label>
          <label>
            <span>Status :</span>
            <select required name="status" value={formData.status} onChange={handleInputChange}>
              <option value="1">Actif</option>
              <option value="0">Inactif</option>
            </select>
          </label>
          <button type="submit">Ajouter un Spot</button>
          <button type="button" className='back' onClick={() => navigate('/spots')}>Annuler</button>
        </form>
      </div>
    </div>
  );
};

export default AddSpot;
