import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import ErrorMessage from '../ErrorMessage';
import Sidebar from '../Sidebar';
import './Users.css';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  // SORT AND FILTER
  const [tri, setTri] = useState('');
  const [triSort, setTriSort] = useState('asc');
  const [filters, setFilters] = useState({
    search: '',
    roleFilters: '',
    statusFilters: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    const buildQueryParams = () => {
      const params = new URLSearchParams();
      if (tri) {
        params.append('tri', tri);
        params.append('triSort', triSort);
      }
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          params.append(key, filters[key]);
        }
      });
      return params.toString();
    };
    const fetchUsers = async () => {
      try {
        const queryParams = buildQueryParams();
        const response = await axios.get(`/users?${queryParams}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setUsers(response.data);
      } catch (error) {
        setError('Impossible de récupérer les utilisateurs.');
      }
    };

    fetchUsers();
  }, [tri, triSort, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleTri = async (triName) => {
    setTri(triName);
    setTriSort(triSort === "asc" ? "desc" : "asc");
  };

  const confirmDelete = async (id) => {
    if (window.confirm("Voulez-vous supprimer cet utilisateur ?")) {
      await handleDelete(id);
    }
  }

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/users/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUsers(users.filter(user => user._id !== id));
    } catch (error) {
      setError('Impossible de supprimer cet utilisateur.');
    }
  };

  return (
    <div className="users-container">
      <Sidebar />
      <div className="users-content">
        <h2>Gérer les Utilisateurs</h2>
        {error && <ErrorMessage message={error} />}
        <h3>Filtres</h3>
        <div id="filters">
          <form className='filters-container'>
            <label className='filters'>
              <span>Recherche</span>
              <input
                type="text"
                name="search"
                placeholder="Recherche"
                value={filters.search}
                onChange={handleFilterChange}
              />
            </label>
            <label className='filters'>
              <span>Role</span>
              <select onChange={handleFilterChange} name="roleFilters">
                <option value="all">Tous</option>
                <option value="admin">Admin</option>
                <option value="user">Utilisateur</option>
              </select>
            </label>
            <label className='filters'>
              <span>Status</span>
              <select onChange={handleFilterChange} name="statusFilters">
                <option value="all">Tous</option>
                <option value="active">Actif</option>
                <option value="inactive">Inactif</option>
              </select>
            </label>
          </form>
        </div>
        <table className="table sortable">
          <thead>
            <tr>
              <th className={`${tri === 'username' ? triSort : ''}`} onClick={() => handleTri('username')}>Pseudo</th>
              <th className={`${tri === 'role' ? triSort : ''}`} onClick={() => handleTri('role')}>Role</th>
              <th className={`${tri === 'status' ? triSort : ''}`} onClick={() => handleTri('status')}>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user._id}>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td className="center">{user.status === 1 ? <img height="48" src="/done.png" alt="Active" /> : <img height="48" src="/hidden.png" alt="Inactive" />}</td>
                <td className='text-right'>
                  <button onClick={() => navigate(`/users/${user._id}`)}>Voir</button>
                  <button className='red' onClick={() => confirmDelete(user._id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
